@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=IBM+Plex+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
pre {
  font-family: 'IBM Plex Mono', monospace;
  max-width: 500px !important;
  white-space: pre-line;
}

a {
  color: black;
}

